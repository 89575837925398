import type { Question } from '@luigi/examkit/typings'
import type { PlanPackage } from '@luigi/devkit/graphql/typings'

export enum QuestionType {
  SingleChoice = 'SingleChoice',
  MultipleChoice = 'MultipleChoice',
  FillInBlank = 'FillInBlank',
  ShortAnswer = 'ShortAnswer',
  RandomQuestions = 'RandomQuestions',
  TrueOrFalse = 'TrueOrFalse',
  ImageSingleChoice = 'ImageSingleChoice',
  ImageMultipleChoice = 'ImageMultipleChoice',
  MultipleBlank = 'MultipleBlank',
}

export enum QuestionRuleType {
  FixedRule = 'FixedRule',
  RandomRule = 'RandomRule',
}

export type SelectableQuestionType = QuestionType.SingleChoice | QuestionType.MultipleChoice | QuestionType.TrueOrFalse

export type Profile = {
  id: string
  avatarUrl: string
  mobile: string
  name: string
  role: string
  createdAt: string
  billingAccount?: {
    id: string
    name: string
    planPackage: Partial<PlanPackage>
  }
}

export enum PlanCode {
  /** 企业版 */
  Ent = 'ent',
  /** 免费版 */
  Free = 'free',
  /** 专业版 */
  Pro = 'pro',
  /** 标准版 */
  Std = 'std',
  /** 旗舰版 */
  Ultra = 'ultra',
  /** 试用版 */
  Trial = 'trial',
}

export type UpdateProfileArgs = {
  name?: string
  avatar?: {
    attachmentId: string
    cropX?: number
    cropY?: number
    cropW?: number
    cropH?: number
  }
}

export enum FileCategory {
  Word = 'Word',
  Excel = 'Excel',
  PDF = 'PDF',
  PPT = 'PPT',
}

export enum CreationType {
  OneLine = 'oneline',
  QuestionBank = 'question_bank',
  KnowledgeBase = 'knowledge_base',
}

export enum ContentType {
  Education = 'education',
  Other = 'other',
}
