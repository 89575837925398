import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'

import { getTemplate, getTemplates } from '@/api/exam'

import type { EntityState, PayloadAction } from '@reduxjs/toolkit'
import type { QuestionBankTemplate } from '@luigi/examkit/typings'
import type { RootState } from '@/redux/store'

type TemplateState = EntityState<QuestionBankTemplate> & {
  isLoading: boolean
  current?: QuestionBankTemplate
  purchaseToken?: string
  qrcodeUrl?: string
  orderNo?: string
  searchText: string
  currentTemplate?: string
}

const templateAdapter = createEntityAdapter<QuestionBankTemplate>({
  selectId: model => model.token,
})

const initialTemplatesState: TemplateState = templateAdapter.getInitialState({
  isLoading: false,
  searchText: '',
})

export const templateSelectors = templateAdapter.getSelectors()

export const templateSlice = createSlice({
  name: 'template',
  initialState: initialTemplatesState,
  reducers: {
    setCurrentTemplate: (state, action: PayloadAction<string | undefined>) => {
      state.currentTemplate = action.payload
    },
    searchTemplates: (state, action) => {
      const searchText = action.payload
      const showTemplates = templateSelectors.selectAll(state).map(template => {
        return {
          ...template,
          hidden: !template.name.includes(searchText),
        }
      })
      templateAdapter.upsertMany(state, showTemplates)
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getTemplatesThunk.fulfilled, (state, action) => {
        if (action.payload) {
          const templates = action.payload.map(template => {
            return {
              ...template,
              hidden: false,
            }
          })
          templateAdapter.upsertMany(state, templates)
        }
      })
      .addCase(getTemplateThunk.fulfilled, (state, action) => {
        if (action.payload) {
          state.current = action.payload
          action.payload && templateAdapter.upsertOne(state, action.payload)
        }
      })
  },
})

export const getAllTemplates = createSelector((state: RootState) => state.template, templateSelectors.selectAll)

export const selectorCurrentTemplate = createSelector(
  (state: RootState) => state.template,
  state => (state.currentTemplate ? templateSelectors.selectById(state, state.currentTemplate) : undefined)
)

export const selectorShowTemplates = createSelector(
  (state: RootState) => state.template,
  state => {
    return templateSelectors.selectAll(state).filter(template => !template.hidden)
  }
)

export const getTemplatesThunk = createAsyncThunk('template/getTemplates', async () => {
  const { data } = await getTemplates()
  if (data) {
    return data
  }
})

export const getTemplateThunk = createAsyncThunk('template/getTemplate', async (id: string) => {
  const { data } = await getTemplate(id)
  if (data) {
    return data
  }
})

export const { searchTemplates, setCurrentTemplate } = templateSlice.actions

export default templateSlice.reducer
