import '@tarojs/plugin-platform-h5/dist/runtime'

import { initPxTransform } from '@tarojs/taro'
import { createRouter, createBrowserHistory, handleAppMountWithTabbar } from '@tarojs/router'
import component from "./app"
import { window } from '@tarojs/runtime'
import { createReactApp } from '@tarojs/plugin-framework-react/dist/runtime'

import * as React from 'react'
import ReactDOM from 'react-dom/client'

import { findDOMNode, render, unstable_batchedUpdates } from 'react-dom'
import { defineCustomElementTaroPullToRefreshCore } from '@tarojs/components/dist/components'


var config = {"router":{"mode":"browser","customRoutes":{"/pages/home/index":"/index","/pages/profile/index":"/profile","/pages/profile/edit/index":"/profile/edit","/pages/signup/bind-mobile/index":"/signup/bind-mobile","/pages/signup/index":"/signup","/pages/signup/mobile/index":"/signup/mobile","/pages/signup/oauth-callback/index":"/signup/oauth-callback","pages/creating/index":"/creating","pages/creation/index":"/creation","pages/edit/index":"/edit","pages/entries/index":"/entries","pages/entries/detail/index":"/entries/detail","pages/privacy/index":"/privacy","pages/terms/index":"/terms","pages/gateway/index":"/gateway","pages/published/index":"/published","pages/templates/index":"/templates"}},"plugins":{"captcha4":{"provider":"wx1629d117cf9be937","version":"2.7.4"}},"lazyCodeLoading":"requiredComponents","pages":["pages/home/index","pages/signup/index","pages/signup/bind-mobile/index","pages/signup/oauth-callback/index","pages/signup/mobile/index","pages/profile/index","pages/profile/edit/index","pages/creation/index","pages/creating/index","pages/edit/index","pages/entries/index","pages/entries/detail/index","pages/privacy/index","pages/terms/index","pages/webview/index","pages/gateway/index","pages/published/index","pages/customer/index","pages/templates/index"],"window":{"backgroundTextStyle":"light","navigationBarBackgroundColor":"#fff","navigationBarTitleText":"WeChat","navigationBarTextStyle":"black"},"tabBar":{"custom":true,"backgroundColor":"#ffffff","selectedColor":"#000000","color":"#000000","borderStyle":"white","list":[{"pagePath":"pages/home/index","text":"主页"},{"pagePath":"pages/profile/index","text":"我的"}]}}
window.__taroAppConfig = config
var tabbarIconPath = []
var tabbarSelectedIconPath = []

if (config.tabBar) {
  var tabbarList = config.tabBar.list
  for (var i = 0; i < tabbarList.length; i++) {
    var t = tabbarList[i]
    if (t.iconPath) {
      t.iconPath = tabbarIconPath[i]
    }
    if (t.selectedIconPath) {
      t.selectedIconPath = tabbarSelectedIconPath[i]
    }
  }
}
config.routes = [
  Object.assign({
  path: 'pages/home/index',
  load: function(context, params) {
    const page = import("./pages/home/index")
    return [page, context, params]
  }
}, {"navigationBarTitleText":"首页","navigationStyle":"custom","enableShareAppMessage":true,"usingComponents":{}}),Object.assign({
  path: 'pages/signup/index',
  load: function(context, params) {
    const page = import("./pages/signup/index")
    return [page, context, params]
  }
}, {"navigationBarTitleText":"登录","navigationStyle":"custom"}),Object.assign({
  path: 'pages/signup/bind-mobile/index',
  load: function(context, params) {
    const page = import("./pages/signup/bind-mobile/index")
    return [page, context, params]
  }
}, {"navigationBarTitleText":"绑定手机号"}),Object.assign({
  path: 'pages/signup/oauth-callback/index',
  load: function(context, params) {
    const page = import("./pages/signup/oauth-callback/index")
    return [page, context, params]
  }
}, {"navigationBarTitleText":"登录"}),Object.assign({
  path: 'pages/signup/mobile/index',
  load: function(context, params) {
    const page = import("./pages/signup/mobile/index")
    return [page, context, params]
  }
}, {"navigationBarTitleText":"手机号登录","navigationStyle":"custom","usingComponents":{"captcha4":"plugin://captcha4/captcha4"}}),Object.assign({
  path: 'pages/profile/index',
  load: function(context, params) {
    const page = import("./pages/profile/index")
    return [page, context, params]
  }
}, {"navigationBarTitleText":"我的","navigationStyle":"custom","usingComponents":{}}),Object.assign({
  path: 'pages/profile/edit/index',
  load: function(context, params) {
    const page = import("./pages/profile/edit/index")
    return [page, context, params]
  }
}, {"navigationBarTitleText":"个人资料"}),Object.assign({
  path: 'pages/creation/index',
  load: function(context, params) {
    const page = import("./pages/creation/index")
    return [page, context, params]
  }
}, {"navigationBarTitleText":"智能创建","navigationStyle":"custom"}),Object.assign({
  path: 'pages/creating/index',
  load: function(context, params) {
    const page = import("./pages/creating/index")
    return [page, context, params]
  }
}, {"navigationBarTitleText":"创建考试"}),Object.assign({
  path: 'pages/edit/index',
  load: function(context, params) {
    const page = import("./pages/edit/index")
    return [page, context, params]
  }
}, {"navigationBarTitleText":"考试"}),Object.assign({
  path: 'pages/entries/index',
  load: function(context, params) {
    const page = import("./pages/entries/index")
    return [page, context, params]
  }
}, {"navigationBarTitleText":"统计"}),Object.assign({
  path: 'pages/entries/detail/index',
  load: function(context, params) {
    const page = import("./pages/entries/detail/index")
    return [page, context, params]
  }
}, {"navigationBarTitleText":"答卷详情"}),Object.assign({
  path: 'pages/privacy/index',
  load: function(context, params) {
    const page = import("./pages/privacy/index")
    return [page, context, params]
  }
}, {"navigationBarTitleText":"隐私政策"}),Object.assign({
  path: 'pages/terms/index',
  load: function(context, params) {
    const page = import("./pages/terms/index")
    return [page, context, params]
  }
}, {"navigationBarTitleText":"使用条款"}),Object.assign({
  path: 'pages/webview/index',
  load: function(context, params) {
    const page = import("./pages/webview/index")
    return [page, context, params]
  }
}, {"navigationBarTitleText":"","enableShareAppMessage":true,"usingComponents":{}}),Object.assign({
  path: 'pages/gateway/index',
  load: function(context, params) {
    const page = import("./pages/gateway/index")
    return [page, context, params]
  }
}, {"navigationBarTitleText":"加载中","navigationStyle":"custom","enableShareAppMessage":true}),Object.assign({
  path: 'pages/published/index',
  load: function(context, params) {
    const page = import("./pages/published/index")
    return [page, context, params]
  }
}, {"navigationBarTitleText":"分享"}),Object.assign({
  path: 'pages/customer/index',
  load: function(context, params) {
    const page = import("./pages/customer/index")
    return [page, context, params]
  }
}, {"navigationBarTitleText":"客户卡片"}),Object.assign({
  path: 'pages/templates/index',
  load: function(context, params) {
    const page = import("./pages/templates/index")
    return [page, context, params]
  }
}, {"navigationBarTitleText":"题库广场","navigationStyle":"custom"})
]
Object.assign(ReactDOM, { findDOMNode, render, unstable_batchedUpdates })
defineCustomElementTaroPullToRefreshCore()

var inst = createReactApp(component, React, ReactDOM, config)
var history = createBrowserHistory({ window })
handleAppMountWithTabbar(config, history)
createRouter(history, inst, config, React)
initPxTransform({
  designWidth: 750,
  deviceRatio: {"375":2,"640":1.17,"750":1,"828":0.905},
  baseFontSize: 20,
  unitPrecision: undefined,
  targetUnit: undefined
})
