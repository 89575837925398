import fetchRestfulRequest from '@/lib/network/taroRequest'

import type { EntityId } from '@reduxjs/toolkit'
import type { APIResponse } from '@luigi/toolkit/utils/execFetchRestfulRequest'
import type {
  AnswerPaper,
  BatchCreateQuestionsResponse,
  CreateAiGenerationVariables,
  Examination,
  ExaminationReport,
  ExaminationVariables,
  Examinee,
  ExamPaper,
  ExamPaperRules,
  Question,
  QuestionBank,
  QuestionBankOverview,
  QuestionBankTemplate,
  QuestionVariables,
  UpdateQuestionVariables,
} from '@luigi/examkit/typings'
import type { ContentType, CreationType } from '@/types'

export const getQuestionBanks = async () =>
  await fetchRestfulRequest<QuestionBank[]>({
    url: '/exam/question_banks',
    method: 'GET',
  })

export const getPublicQuestionBanks = async () =>
  await fetchRestfulRequest<QuestionBank[]>({
    url: '/exam/public_question_banks',
    method: 'GET',
  })

export const getQuestionBankDetail = async ({ token }: { token: string }) =>
  await fetchRestfulRequest<QuestionBank>({
    url: `/exam/question_banks/${token}`,
    method: 'GET',
  })

export const createQuestionBank = async (body: { name: string; color: string }) =>
  await fetchRestfulRequest<QuestionBank>({
    url: '/exam/question_banks',
    method: 'POST',
    body,
  })

export const updateQuestionBank = async (id: EntityId, body: { name: string }) =>
  await fetchRestfulRequest({
    url: `/exam/question_banks/${id}`,
    method: 'PUT',
    body,
  })

export const copyQuestionBank = async (id: string) =>
  await fetchRestfulRequest<{ token: string }>({
    url: `/exam/question_banks/${id}/copies`,
    method: 'POST',
  })

export const destroyQuestionBank = async (questionBankId: EntityId) =>
  await fetchRestfulRequest({
    url: `/exam/question_banks/${questionBankId}`,
    method: 'DELETE',
  })

export const createQuestion = async ({ bankToken, body }: { bankToken: string; body: QuestionVariables }) =>
  await fetchRestfulRequest<Question>({
    url: `/exam/question_banks/${bankToken}/questions`,
    method: 'POST',
    body,
  })

export const batchCreateQuestions = async ({
  bankToken,
  questions,
  bankName,
  color,
  aiJobId,
}: {
  bankToken?: string
  bankName?: string
  questions: QuestionVariables[]
  color?: string
  aiJobId?: string
}) =>
  await fetchRestfulRequest<BatchCreateQuestionsResponse>({
    url: `/exam/batch_questions`,
    method: 'POST',
    body: {
      questionBank: {
        id: bankToken,
        name: bankName,
        color,
      },
      questions,
      aiJobId,
    },
  })

export const destroyQuestion = async ({ bankToken, apiCode }: { bankToken: string; apiCode: string }) =>
  await fetchRestfulRequest({
    url: `/exam/question_banks/${bankToken}/questions/${apiCode}`,
    method: 'DELETE',
  })

export const updateQuestion = async ({ bankToken, body }: { bankToken: string; body: QuestionVariables }) =>
  await fetchRestfulRequest<Question>({
    url: `/exam/question_banks/${bankToken}/questions/${body.apiCode}`,
    method: 'PUT',
    body,
  })

export const getQuestionsByBankToken = async ({ bankToken, queryString }: { bankToken: string; queryString: string }) =>
  await fetchRestfulRequest<Question[]>({
    url: `/exam/question_banks/${bankToken}/questions${queryString}`,
    method: 'GET',
  })

export const getQuestionBankOverview = async ({ bankToken }: { bankToken: string }) =>
  await fetchRestfulRequest<QuestionBankOverview>({
    url: `/exam/question_banks/${bankToken}/overview`,
    method: 'GET',
  })

export const createPaper = async (body: { name: string }) =>
  await fetchRestfulRequest<ExamPaper>({
    url: '/exam/exam_papers',
    method: 'POST',
    body,
  })

export const updatePaper = async ({
  token,
  questionsCount,
  totalScore,
  rules,
}: {
  token: string
  questionsCount: number
  totalScore: number
  rules: ExamPaperRules[]
}) => {
  return await fetchRestfulRequest<ExamPaper>({
    url: `/exam/exam_papers/${token}`,
    method: 'PUT',
    body: {
      questionsCount,
      totalScore,
      rules,
    },
  })
}

export const getPapers = async (queryString: string) =>
  await fetchRestfulRequest<ExamPaper[]>({
    url: `/exam/exam_papers${queryString}`,
    method: 'GET',
  })
export const getExamPaperByToken = async ({ token }: { token: string }) =>
  await fetchRestfulRequest<ExamPaper>({
    url: `/exam/exam_papers/${token}`,
    method: 'GET',
  })

export const getExaminations = async (queryString: string) =>
  await fetchRestfulRequest<Examination[]>({
    url: `/exam/examinations${queryString}`,
    method: 'GET',
  })

export const getExamination = async ({ token }: { token: string }) =>
  await fetchRestfulRequest<Examination>({
    url: `/exam/examinations/${token}`,
    method: 'GET',
  })

export const createExamination = async (body: ExaminationVariables) =>
  await fetchRestfulRequest<Examination>({
    url: `/exam/examinations`,
    method: 'POST',
    body,
  })

export const updateExamination = async (body: ExaminationVariables) =>
  await fetchRestfulRequest<Examination>({
    url: `/exam/examinations/${body.token}`,
    method: 'PUT',
    body,
  })

export const destroyExamination = async ({ token }: { token: string }) => {
  return (await fetchRestfulRequest({
    url: `/exam/examinations/${token}`,
    method: 'DELETE',
  })) as APIResponse<null>
}

export const generateExamDescription = async ({ token }: { token: string }) =>
  await fetchRestfulRequest<{ description: string }>({
    url: `/exam/exam_description_generations?examination_id=${token}`,
    method: 'POST',
  })

export const getExaminationReports = async (token: string) =>
  await fetchRestfulRequest<ExaminationReport>({
    url: `/exam/examinations/${token}/reports`,
    method: 'GET',
  })

export const getExaminationAnswers = async (token: string) =>
  await fetchRestfulRequest<AnswerPaper>({
    url: `/exam/answer_papers/${token}`,
    method: 'GET',
  })

export const getExaminees = async ({ number, size }: { number: number; size: number }) =>
  await fetchRestfulRequest<Examinee[]>({
    url: `/exam/examinees?page[number]=${number}&page[size]=${size}`,
    method: 'GET',
  })

export const getCreationType = async (body: { content: string }) => {
  return await fetchRestfulRequest<{
    creation_type: CreationType
    content_type: ContentType
  }>({
    url: `/exam/ai/creation_types`,
    method: 'POST',
    body,
  })
}

export const batchUpdateQuestions = async (questions: UpdateQuestionVariables[]) =>
  await fetchRestfulRequest<{ questions: Question[] }>({
    url: `/exam/batch_questions`,
    method: 'PUT',
    body: {
      questions,
    },
  })

export const createAIGenerations = async (body: CreateAiGenerationVariables) => {
  return await fetchRestfulRequest<{ id: string }>({
    url: '/exam/ai/generations',
    method: 'POST',
    body,
  })
}

export const stopAIGenerateTask = async (jobId: string, name?: string) => {
  const body = name
    ? {
        body: {
          then: name,
        },
      }
    : {}
  await fetchRestfulRequest({
    url: `/exam/ai/generations/${jobId}/interruptions`,
    method: 'POST',
    ...body,
  })
  // captureMessageToSentry({
  //   message: 'user_canceled',
  //   tags: { sub_system: 'exam', process: 'generating_questions_by_ai' },
  // })
}

export const gotoFormatQuestionBanks = async (jobId: string) => {
  return await fetchRestfulRequest({
    url: '/exam/ai/question_banks/go_to_format',
    method: 'POST',
    body: { id: jobId },
  })
}

export const getTemplates = async () => {
  return await fetchRestfulRequest<QuestionBankTemplate[]>({
    url: `/exam/templates?page[number]=1&page[size]=500`,
    method: 'GET',
  })
}

export const getTemplate = async (id: string) => {
  return await fetchRestfulRequest<QuestionBankTemplate>({
    url: `/exam/templates/${id}`,
    method: 'GET',
  })
}

export const copyTemplate = async (id: string) => {
  return await fetchRestfulRequest<QuestionBank>({
    url: `/exam/templates/${id}/copies`,
    method: 'POST',
  })
}
